<template>
  <div class="alipay">
    <div class="pay-prompt" v-if="isWeixin">
      <div class="pay-title">支付流程</div>
      <div class="pay-process">
        <div class="pay-process-item">
          <div class="process-flag">1</div>
          <div class="process-text">请点击右上角“...”</div>
        </div>
        <div class="pay-process-item">
          <div class="process-flag">2</div>
          <div class="process-text">使用【手机浏览器】打开</div>
        </div>
        <div class="pay-process-item">
          <div class="process-flag">3</div>
          <div class="process-text">完成支付宝付款</div>
        </div>
        <div class="pay-process-item">
          <div class="process-flag">4</div>
          <div class="process-text">点击微信图标返回本页</div>
        </div>
        <div class="pay-process-item">
          <div class="process-flag">5</div>
          <div class="process-text">
            <div>完成上述补助，点击下方按钮</div>
            <div class="view-result" @click="viewResult()">查看支付结果</div>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <van-empty description="加载中......" />
    </div>
  </div>
</template>

<script>
import { isWeixin } from "@/utils/index.js";
export default {
  name: "aliPay",

  data() {
    return {
      isWeixin: isWeixin(),
    };
  },

  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (this.isWeixin) {
        console.log("微信环境");
        console.log(this.$route.query.alipay_url)
          
      } else {
        console.log("非微信环境，直接进入支付宝支付环境");
        console.log(this.$route.query.alipay_url)
        setTimeout(() => {
          window.open(this.$route.query.alipay_url, "_self")
        }, 1500);
      }
    },
    viewResult(){
      console.log("查看支付结果")
    },
  },
};
</script>

<style lang="scss" scoped>
.alipay {
  height: 100%;
  width: 100%;
  // background: url(../../assets/img/payBackground.png) no-repeat center;
  // background-size: 100% 50%;
  // background-attachment: fixed;
  color: #333;
  .pay-prompt {
    margin: 20px 40px;
    padding: 20px;
    background: #e4e8f4;
    border-radius: 10px;

    .pay-title {
      text-align: center;
      font-size: 30px;
      font-weight: 600;
      margin-bottom: 20px;
    }
    .pay-process {
      background: #fff;
      border-radius: 10px;
      padding: 15px 10px;
      .pay-process-item {
        padding: 15px 10px;
        display: flex;
        border-bottom: 1px solid #f1f3f4;
        .process-flag {
          background: #7fa4fd;
          border-radius: 50%;
          color: #fff;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          margin-right: 20px;
        }
        .process-text {
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          font-size: 25px;
          .view-result {
            margin-top: 20px;
            padding: 20px 25px;
            color: #454ea9;
            background: #e5eafd;
            border-radius: 10px;
            width: fit-content;
          }
        }
      }
      .pay-process-item:last-child {
        border-bottom: 0px solid #f1f3f4;
      }
    }
  }
}
</style>